import { useAppContext } from "@/context/Context";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import logo from "../../../public/images/logo/logo.png";

import Nav from "../Nav";

const HeaderFour = ({ gapSpaceBetween, sticky, navigationEnd, container, flexDirection }) => {
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } =
    useAppContext();
  const [isSticky, setIsSticky] = useState(false);
  const { total_items } = useSelector((state) => state.CartReducer);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`rbt-header-wrapper ${sticky} ${
          (isSticky && sticky) ? "rbt-sticky" : ""
        }`}
      >
        {/* {(isSticky && sticky) && <div
        className={`rbt-header-top rbt-header-top-1 variation-height-50 ${gapSpaceBetween} border-top-bar-primary-color rbt-border-bottom d-none d-xl-block`}
      >
        <div className={`${container}`}>
          <div className={`rbt-header-sec align-items-center ${flexDirection}`}>
            <div className="rbt-header-sec-col rbt-header-left">
              <div className="rbt-header-content">
                <div className="header-info">
                  <ul className="rbt-information-list">
                    <li>
                      <Link href={`Tel: +1 587-349-1110`}>
                        <i className="feather-phone"></i>+1 587-349-1110
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="rbt-separator"></div>
                <div className="header-info">
                  <ul className="social-share-transparent">
                    <li>
                      <Link href="https://www.facebook.com/GDCollege9/">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://ca.linkedin.com/company/gdcollege">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li> 
                    <li>
                      <Link href="https://www.instagram.com/gd_college/">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.youtube.com/@gdcollege">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                    <li className='tiktok-icon'>
                      <Link href="https://www.tiktok.com/@gdcollege3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="rbt-header-sec-col rbt-header-right">
              <div className="rbt-header-content">
                <div className="header-info" onClick={() => setToggle(!toggle)}>
                  <div className="header-right-btn d-flex">
                    <Link className={`rbt-btn rbt-switch-btn btn-gradient btn-xs`} href="/contact" style={{background: 'white', color: 'black'}}>
                      <span data-text={`Ask Your Query`}>Ask Your Query</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>} */}
        <div className={`${container} ${gapSpaceBetween}`}>
          <div className={`mainbar-row ${navigationEnd} align-items-center`}>
            <div className="header-left">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    width={152}
                    height={50}
                    priority={true}
                    alt="GD College"
                  />
                </Link>
              </div>
            </div>
            <div className="rbt-main-navigation d-none d-xl-block me-0">
              <Nav />
            </div>
            <div className="header-right">
              <ul className="quick-access">
                {/* <li className="access-icon rbt-user-wrapper right-align-dropdown">
                  <Link className="rbt-round-btn" href="#">
                    <i className="feather-user"></i>
                  </Link>
                  <User />
                </li> */}
                {/* <li
                  className="access-icon rbt-mini-cart"
                  onClick={() => setCart(!cartToggle)}
                >
                  <Link
                    className="rbt-cart-sidenav-activation rbt-round-btn"
                    href="#"
                  >
                    <i className="feather-shopping-cart"></i>
                    <span className="rbt-cart-count">{total_items}</span>
                  </Link>
                </li> */}
                {/* <li className="access-icon">
                  <Link
                    className={`search-trigger-active rbt-round-btn ${
                      search ? "" : "open"
                    }`}
                    href="#"
                    onClick={() => setSearch(!search)}
                  >
                    <i className="feather-search"></i>
                  </Link>
                </li> */}
              </ul>

              <div className="mobile-menu-bar d-block d-xl-none">
                <div className="header-right-btn d-flex" 
                      style={{
                        position: 'absolute',
                        right: '65px',
                        marginTop: '-6px'
                      }}
                >
                  <Link
                    className={`rbt-btn radius-round btn-sm hover-transform-non`}
                    href={`Tel: +1 587-349-1110`}
                    style={{ color: "white" }}
                  >
                    <span data-text={`Contact Us`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                      </svg>
                    </span>
                  </Link>
                </div>
                <div className="hamberger">
                  <button
                    className="hamberger-button rbt-round-btn"
                    onClick={() => setMobile(!mobile)}
                  >
                    <i className="feather-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Search /> */}
      </div>
    </>
  );
};

export default HeaderFour;
